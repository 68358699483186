export const IMAGE = {
  1: require("./M-1.png"),
  2: require("./M-2.png"),
  3: require("./M-3.png"),
  4: require("./M-4.png"),
  5: require("./M-5.png"),
  6: require("./M-6.png"),
  7: require("./M-7.png"),
  8: require("./M-8.png"),
  9: require("./M-9.jpeg"),
  10: require("./M-10.png"),
  11: require("./M-11.png"),
};